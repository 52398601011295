





































































































































































































.invite-modal > .modal-content
  width: 800px

.invite-modal .modal-scroll
  max-height 400px

.btn-upload
  line-height 2

.btn-download
  display flex
  align-items center
  height 100%
  min-height 2.572em
  padding 0 8px
  text-transform uppercase
  border-radius 3px
  font-size 14px
  font-weight 500
  margin-right 8px
  border 1px solid $green

ol
  margin 5px 0
  &.main li
    padding 5px

ul
  margin 5px 0
  list-style-type disc
  &.inner li
    padding 0
