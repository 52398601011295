.csvOption {
  color: #1976d2;
  cursor: pointer;
}
.csvOption:hover {
  text-decoration: underline;
}
.invite-modal > .modal-content {
  width: 800px;
}
.invite-modal {
  max-height: 400px;
}
/*# sourceMappingURL=src/pages/members/list/member-create.css.map */