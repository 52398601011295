


























































































































.tcell
  display: flex;
  align-items: center;
  justify-content: flex-start;

.avatar
  margin-right 15px

.member-menu
  position: absolute;
  top: 0px;
  right: 12px;

.actions
  height 50px

.member
  position relative

.small-member-list
  .member
    width 96%
  .small-hide
    display none
  .member
    max-height 70px
