




































































































































































































.csvOption
  color $primary
  cursor pointer
  &:hover
    text-decoration underline
.invite-modal > .modal-content
  width: 800px

.invite-modal
  max-height 400px
